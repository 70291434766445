<template>
  <v-container
    fluid
    class="d-flex flex-column"
  >
    <v-btn
      v-for="shop in shops"
      :key="shop.uri"
      class="my-1"
      :href="`/${shop.uri}`"
    >
      <v-icon left>mdi-store</v-icon>
      {{ shop.organization_name }}
    </v-btn>
  </v-container>
</template>

<script>
export default {
  name: 'Market',
  data() {
    return {
      shops: []
    }
  },
  mounted(){
    this.fetch()
  },
  methods: {
    async fetch() {
      this.shops = await this.api.get('/shop/market')
    },
    openShop(uri){
      this.$router.go(`${window.location.origin}/${uri}`)
    }
  },
}
</script>