<template>
  <v-app>
    <v-app-bar
      app
      color="white"
      hide-on-scroll
      elevation="0"
      :prominent="!!shop.banner_url"
      :src="shop.banner_url"
    >
      <mb-logo
        :shop="shop"
        @initShop="initShop"
      />

      <v-spacer />
  
      <mb-account-menu
        v-if="shop.context"
      />
  
    </v-app-bar>
    <v-main>
      <router-view 
        :fire="fire"
        @initShop="initShop"
      />
    </v-main>
    <snackbar />
  </v-app>
</template>

<script>
import MbLogo from './Logo'
import Snackbar from '@/shared/Snackbar'

export default {
  name: 'App',
  components: {
    MbLogo,
    Snackbar,
  },
  data() {
    return {
      shop: {},
      fire: false
    }
  },
  methods: {
    async initShop(uri){
      if (uri) {
        this.shop = await this.api.get(`shop/shop/${uri}`) || {}
        if (this.shop && this.shop.context && this.shop.uri) {
          await this.api.setAuth(null, null, this.shop.context, this.shop.uri)
        }
      }
      console.log('App mounted...', {
        shopURI: this.shopURI,
        context: this.context,
        version: process.env.VUE_APP_VERSION
      })
      this.fire = true
    }
  },
};
</script>

<style>
#v-app {
  text-align: center;
}
</style>