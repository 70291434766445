<template>
  <v-container
    fluid
    class="ma-0 pa-0"
  >
    <v-dialog
      v-model="show"
      width="500"
      transition="dialog-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          small
          v-bind="attrs"
          v-on="on"
        >
          <v-icon small>mdi-camera</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Upload Banner
        </v-card-title>

        <v-card-text>
          <image-uploader
            label="Click or Drop Banner Image (1Mb max, png/jpeg only)"
            purpose="banner"
            :shop-id="shop.context"
            @refresh="refresh"
          />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="removeBanner"
          >
            remove banner
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="show=false"
          >
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ImageUploader from './ImageUploader.vue'

export default {
  components: { ImageUploader },
  props: {
    shop: {
      type: Object,
      default: ()=>{return {}}
    },
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    async removeBanner() {
      await this.api.delete('/shop/banner')
      this.refresh()
    },
    refresh(){
      this.show = false
      this.$emit('initShop', this.shopURI)
    },
  },
}
</script>