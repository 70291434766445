<template>
  <div class="d-flex align-center font-weight-bold text-h5 text-no-wrap">
    <v-img
      v-if="!shop.title"
      alt="Merchbase Logo"
      class="shrink mr-2"
      contain
      src="@/assets/Merchbase_Logo.svg"
      transition="scale-transition"
      width="120"
    />
    {{ shop.title }}
    <admin-banner
      :shop="shop"
      v-if="editor"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import AdminBanner from './admin/Banner'

export default {
  components: {
    AdminBanner
  },
  props: {
    shop: {
      type: Object,
      default: ()=>{return {}}
    },
  },
  computed: {
    editor(){
      return this.shop && this.shop.context
        && this.auth.roles && this.auth.roles.includes('editor')
    }
  }
}
</script>