<template>
  <v-container fluid>
    <mb-content
      name="market_about"
      class="mx-14"
    />
    <mb-market
      v-if="fire"
    />
  </v-container>
</template>

<script>
export default {
  name: 'shop',
  props: {
    fire: {
      type: Boolean,
      default: ()=>false
    },
  },
  mounted(){
    this.$emit('initShop', this.$route.params.uri)
  },
}
</script>